import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);
import { showNotification } from '@mantine/notifications';
import { BaseMutationOptions } from '@apollo/client';
import { DEFAULT_ERROR_MESSAGE } from '../constants';
import { ProjectExcessGeneralLiabilityBuildoutSchedule } from '../graphql/types.generated';

const AMERICA_LOS_ANGELES_TZ = 'America/Los_Angeles';

type AddressComponents = {
  long_name: string;
  short_name: string;
  types: string[];
};

const onApolloError = (content?: string) => {
  const curry: BaseMutationOptions['onError'] = (error) =>
    showNotification({ message: content || error.message || DEFAULT_ERROR_MESSAGE, color: 'red' });

  return curry;
};

export const makeAddress = ({
  address1,
  address2,
  city,
  state,
  zipcode,
}: {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
}) => {
  return [address1, address2, city, state, zipcode].filter((a) => a).join(', ');
};

export const hasAddress = ({
  address1,
  city,
  state,
  zipcode,
}: {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
}) => {
  return [address1, city, state, zipcode].every((a) => !!a);
};

const findMatchingAddress = (addressComponents: AddressComponents[], addressType: string) => {
  const matchingAddress = addressComponents.find((addressComponent) => {
    return addressComponent.types.indexOf(addressType) !== -1;
  });
  return matchingAddress;
};

export const parseAddress = (addressComponents: AddressComponents[], addressType: string) => {
  const matchedAddress = findMatchingAddress(addressComponents, addressType);
  return matchedAddress?.long_name;
};

export const parseAddressState = (addressComponents: AddressComponents[], addressType: string) => {
  const matchedAddress = findMatchingAddress(addressComponents, addressType);
  return matchedAddress?.short_name;
};

export const getCorrectDateWithTimezoneOffset = (date: string | Date): Date => {
  return dayjs(date).tz(AMERICA_LOS_ANGELES_TZ).toDate();
};

// default to PST timezone unless otherwise specific
export const getDateStringTimezone = (date: Date, timeZoneProvided?: string): string =>
  date.toLocaleDateString('en-US', {
    timeZone: timeZoneProvided ?? AMERICA_LOS_ANGELES_TZ,
  });

export const generateBuildoutScheduleInitialValues = (
  effectiveDate: string,
  expirationDate: string,
  exposureId: string
): Array<ProjectExcessGeneralLiabilityBuildoutSchedule> => {
  const parsedEffectiveDate = dayjs(effectiveDate);
  const parsedExpirationDate = dayjs(expirationDate);
  const projectLengthMonths = parsedExpirationDate.diff(parsedEffectiveDate, 'month');

  const projectLengthYears = Math.ceil(projectLengthMonths / 12);
  const allocationPerYear = 1 / projectLengthYears;
  let totalAllocated = 0;

  const initialData: Array<ProjectExcessGeneralLiabilityBuildoutSchedule> = [];
  for (let i = 0; i < projectLengthYears; i++) {
    if (i < projectLengthYears - 1) {
      initialData.push({
        exposureId,
        year: (i + 1).toString(),
        percentage: parseFloat(allocationPerYear.toFixed(2)),
      });
      totalAllocated += parseFloat(allocationPerYear.toFixed(2));
    } else {
      // For the last year, adjust the allocation to ensure the sum is equal to 1
      initialData.push({
        exposureId,
        year: (i + 1).toString(),
        percentage: parseFloat((1 - totalAllocated).toFixed(2)),
      });
    }
  }

  return initialData;
};

export type Nullable<T> = { [K in keyof T]: T[K] | null };

export default onApolloError;
