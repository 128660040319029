import { isEmpty, isNil, isNumber, isString } from 'lodash';
import { CellValue, DateCell, RowCells, StringCell, Validation } from './InputGrid';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export const vinValidator = <Row extends RowCells>(r: Row & { vin: CellValue }) => {
  const l = r?.vin?.toString();
  if (isEmpty(l) || l?.length === 17) {
    return {
      status: Validation.OK,
      message: null,
    };
  }
  return { status: Validation.WARNING, message: 'must be 17 characters' };
};

export const required =
  <Row extends RowCells>(field: keyof Row) =>
  (row: Row) => {
    if (isNil(row[field])) {
      return { status: Validation.ERROR, message: 'required' };
    }
    return { status: Validation.OK, message: null };
  };

/**
 *
 * @param field - the column of the row that is required
 * @param and - the additional condition that must be met
 * @returns Validation Status and Message
 */
export const requiredAnd =
  <Row extends RowCells>(field: keyof Row, and: (row: Row) => boolean) =>
  (row: Row) => {
    if (isNil(row[field])) {
      return { status: Validation.ERROR, message: 'required' };
    }

    if (!and(row)) {
      return { status: Validation.ERROR, message: 'invalid value' };
    }
    return { status: Validation.OK, message: null };
  };

export const geqZero = <Row extends RowCells>(row: Row, field: keyof Row) => {
  const value = row[field];
  if (isNumber(value)) {
    return value >= 0;
  }

  return true;
};

export const lossFieldValidator =
  <Row extends RowCells>(field: keyof Row) =>
  (row: Row) => {
    const value = row[field];

    if (isNil(value)) {
      return { status: Validation.ERROR, message: 'required' };
    }

    if (isString(value) && value === '') {
      return { status: Validation.ERROR, message: 'required' };
    }

    if (isNumber(value) && value < 0) {
      return { status: Validation.ERROR, message: 'must be greater than or equal to zero' };
    }

    if (isNumber(value) && value > 100_000_000) {
      return { status: Validation.WARNING, message: 'value greater than 100,000,000' };
    }

    return { status: Validation.OK, message: null };
  };

export const lossFieldWithOutsizedLossesValidator =
  <Row extends RowCells>(field: keyof Row, hasOutsizedLargeLossValueField: keyof Row) =>
  (row: Row) => {
    const value = row[field];
    if (isNil(value)) {
      return { status: Validation.ERROR, message: 'required' };
    }

    if (isNumber(value) && value < 0) {
      return { status: Validation.ERROR, message: 'must be greater than or equal to zero' };
    }

    if (!isNil(row[hasOutsizedLargeLossValueField]) && row[hasOutsizedLargeLossValueField]) {
      return {
        status: Validation.ERROR,
        message: 'total large losses are more than loss summary',
      };
    }
    return { status: Validation.OK, message: null };
  };

export const buildZipCodeValidator =
  (validZipCodes: Record<string, Set<string>>) =>
  <Row extends RowCells>(row: Row & { zipCode: StringCell; state: StringCell }) => {
    if (isNil(row?.zipCode) || row.zipCode === '') {
      return { status: Validation.ERROR, message: 'required' };
    }

    const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

    if (!re.test(row.zipCode)) {
      return { status: Validation.ERROR, message: 'invalid zip code' };
    }

    if (!isNil(row.state)) {
      const zipCodes = validZipCodes[row.state];
      if (!isNil(zipCodes) && !zipCodes.has(row.zipCode)) {
        return {
          status: Validation.ERROR,
          message: `unsupported zip code ${row.zipCode} for state ${row.state}`,
        };
      }
    }

    return { status: Validation.OK, message: null };
  };

export const JAN_2010 = dayjs('1/1/2010');

export const requiredDate =
  <Row extends RowCells>({
    field,
    minDate,
    maxDate,
  }: {
    field: keyof ({ [key in keyof Row]: DateCell } & Row);
    minDate?: Dayjs;
    maxDate: Dayjs;
  }) =>
  (row: Row) => {
    const value = row[field];

    if (isNil(value)) {
      return { status: Validation.ERROR, message: 'required' };
    }

    if (isString(value)) {
      if (dayjs(value).isValid()) {
        return { status: Validation.OK, message: null };
      }
      return { status: Validation.ERROR, message: 'invalid date' };
    }

    if (dayjs.isDayjs(value)) {
      if (!value.isValid()) {
        return { status: Validation.ERROR, message: 'invalid date' };
      }

      if (!isNil(minDate) && value.isBefore(minDate)) {
        return {
          status: Validation.ERROR,
          message: 'Date Must Be After \n' + minDate.format('MM/DD/YYYY'),
        };
      }

      if (!isNil(maxDate) && value.isSameOrAfter(maxDate)) {
        return {
          status: Validation.ERROR,
          message: 'Date Must Be Before ' + maxDate.format('MM/DD/YYYY'),
        };
      }
    }

    return { status: Validation.OK, message: null };
  };
