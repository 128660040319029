/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { isNil } from 'lodash';
import React from 'react';
import { Validation, Validator } from './InputGrid';

const ToolTip = (props: any) => {
  const { validator }: { validator: Validator<any> } = props;
  const { status, message } = validator(props.data, props.api);
  return isNil(message) || status === Validation.OK ? undefined : (
    <div
      style={{
        border: '1px solid cornflowerblue',
        overflow: 'visible',
        flex: 1,
        backgroundColor: 'white',
      }}
    >
      <p style={{ margin: '5px', whiteSpace: 'nowrap' }}>
        <span style={{ fontWeight: 'bold' }}>{message}</span>
      </p>
    </div>
  );
};

export default ToolTip;
