/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AgGridReact } from 'ag-grid-react';
import { Badge, Button, FileButton, Group, Menu, Title, rem } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { isBoolean, isEmpty, isNil, isNull, isString, toNumber } from 'lodash';
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import ToolTip from './ToolTip';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { AddCircle } from '@emotion-icons/ionicons-outline/AddCircle';
import { CloseCircle } from '@emotion-icons/ionicons-outline/CloseCircle';
import { CloudUpload } from '@emotion-icons/ionicons-outline/CloudUpload';
import { ArrowDownCircle } from '@emotion-icons/ionicons-outline/ArrowDownCircle';
import { Flash } from '@emotion-icons/ionicons-outline/Flash';
import { Duplicate } from '@emotion-icons/ionicons-outline/Duplicate';
import { AlertCircle } from '@emotion-icons/ionicons-outline/AlertCircle';
import { extract } from 'fuzzball';

import {
  ColDef as AgColDef,
  CellClassParams,
  CellPosition,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRichCellEditorParams,
  IRowNode,
  ITooltipParams,
  ProcessDataFromClipboardParams,
  SideBarDef,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import numeral from 'numeral';
import { requiredAnd } from './agValidators';
import { EMPTY_OBJECT } from '../../constants';
import dayjs, { Dayjs } from 'dayjs';
import { ChevronDown } from '@emotion-icons/ionicons-outline/ChevronDown';
import { defaultNumberPaste } from './helpers';
import { Sparkles, Trash } from '@emotion-icons/ionicons-outline';
import UploadLossRunFileModal from '../Forms/UploadLossRunFileModal';
import { openModal } from '@mantine/modals';

/**
 * Input Grid Column Types
 */
export enum InputType {
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  STATIC = 'STATIC',
  COMPONENT = 'COMPONENT',
}

/**
 * Input Grid Validation Statuses
 *
 * `ERROR` prevents submission
 */
export enum Validation {
  OK = 'VALID',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

type RefData = Record<string, string>;

type Cell<T> = T | undefined | null;

export type StringCell = Cell<string>;

export type BoolCell = Cell<boolean>;

export type ComponentCell = Cell<unknown>;

export type NumberCell = Cell<number>;

export type DateCell = Cell<Dayjs>;

export type CellValue = StringCell | BoolCell | NumberCell | DateCell | ComponentCell;

export type RowCells = Record<string, CellValue>;

export type PasteOverride = <Row extends RowCells>(value: string, row: Row) => string;

export type DefaultRowBuilder<Row extends RowCells> = (gridApi: GridApi<any>) => (Row: Row) => Row;

/**
 * Validator Function takes other row values and returns a validation status and a message to be displayed on the tool tip's
 */
export type Validator<Row extends RowCells> = (
  row: Row,
  gridApi: GridApi
) => {
  status: Validation;
  message: string | null;
};

/**
 * A column with drop down select cells
 */
export type SelectColumn<Row extends RowCells> = {
  inputType: InputType.SELECT;
  displayName: string;
  //the name to use when referencing the column with the grid api
  fieldName: keyof Row & string;
  // use this callback to filter the select value based on other columns
  // i.e. data.filter((x) => x.typeOfVehicle === row.typeOfVehicle)
  selectValues: (row: Row) => {
    values: string[];
  };
  // map of label: value for all possible select options
  refData?: RefData;
  // alternative to refData, use these functions together to dynamically use labels/value
  formatter?: {
    parseInput: (newValue: StringCell, row: Row) => StringCell;
    formatOutput: (currentValue: StringCell, row: Row) => StringCell;
  };
  //is there a default fill value, will display lightning bolt if so
  defaultable?: boolean;
  // validator for cell data
  validator: Validator<Row> | null;
  minWidth?: number;
  hidden?: boolean;
  pasteOverride?: PasteOverride;
};

/**
 * A column with text input cells
 *
 */
export type TextColumn<Row extends RowCells> = {
  inputType: InputType.TEXT;
  //the column name
  displayName: string;
  //the name to use when referencing the column with the grid api
  fieldName: keyof Row & string;
  // validator for cell data
  validator: Validator<Row> | null;
  minWidth?: number;
  hidden?: boolean;
  refData?: RefData;
  pasteOverride?: PasteOverride;
};

export type NumberColumn<Row extends RowCells> = {
  inputType: InputType.NUMBER;
  //the column name
  displayName: string;
  //the name to use when referencing the column with the grid api
  fieldName: keyof Row & string;
  // validator for cell data
  validator: Validator<Row> | null;
  minWidth?: number;
  hidden?: boolean;
  refData?: RefData;
  formatter?: 'dollar';
  pasteOverride?: PasteOverride;
};

export type BooleanColumn<Row extends RowCells> = {
  inputType: InputType.BOOLEAN;
  displayName: string;
  // the name to use when referencing the column with the grid api
  fieldName: keyof Row & string;
  // is there a default fill value, will display lightning bolt if so
  defaultable?: boolean;
  required: boolean;
  minWidth?: number;
  hidden?: boolean;
  pasteOverride?: PasteOverride;
};

export type DateColumn<Row extends RowCells> = {
  inputType: InputType.DATE;
  //the column name
  displayName: string;
  //the name to use when referencing the column with the grid api
  fieldName: keyof Row & string;
  // validator for cell data
  validator: Validator<Row> | null;
  minWidth?: number;
  hidden?: boolean;
  pasteOverride?: PasteOverride;
};

export type StaticColumn<Row extends RowCells> = {
  inputType: InputType.STATIC;
  //the column name
  displayName: string;
  //the name to use when referencing the column with the grid api
  fieldName: keyof Row & string;
  //value to display in column for each row
  value: (row: Row) => StringCell;
  minWidth?: number;
  hidden?: boolean;
};

export type ComponentColumn<Row extends RowCells> = {
  inputType: InputType.COMPONENT;
  //the column name
  displayName: string;
  //the name to use when referencing the column with the grid api
  fieldName: keyof Row & string;
  //value to display in column for each row
  value: (row: Row) => any; // data
  render: (row: Row) => React.ReactNode;
  minWidth?: number;
  hidden?: boolean;
};

export type ColDef<Row extends RowCells> =
  | SelectColumn<Row>
  | TextColumn<Row>
  | NumberColumn<Row>
  | BooleanColumn<Row>
  | DateColumn<Row>
  | StaticColumn<Row>
  | ComponentColumn<Row>;

type GridCol = NonNullable<GridOptions['columnDefs']>[0];

const getRowValues = (api: GridApi, columnApi: ColumnApi, node: IRowNode<any>) => {
  const columns = columnApi.getAllGridColumns().map((col) => col.getColId());
  const rowData = columns.reduce((agg, id) => {
    return { ...agg, [id]: api.getValue(id, node) };
  }, {});

  return rowData;
};

const validationColor =
  <Row extends RowCells>(fn: Validator<Row>) =>
  (params: CellClassParams) => {
    if (params.node.rowPinned) {
      return null;
    }
    const data = getRowValues(params.api, params.columnApi, params.node);

    const { status } = fn(data as Row, params.api);

    switch (status) {
      case Validation.ERROR: {
        // light red
        return { backgroundColor: '#ffb3ba' };
      }
      // light yellow
      case Validation.WARNING: {
        return { backgroundColor: '#ffffba' };
      }
      default:
        return null;
    }
  };

/**
 * flattens the output of our graphql label/value constants to an object of the shape ```{[value]: label}```
 * @param input
 *
 * @returns
 */
export const toRefDict = (input: { label: string; value: string }[] | undefined) => {
  if (isNil(input)) {
    return EMPTY_OBJECT;
  }
  return input.reduce<Record<string, string>>(
    (agg, curr) => ({ ...agg, [curr.value]: curr.label }),
    {}
  );
};

const toolTipValueGetter = (params: ITooltipParams) => {
  if (!params.node) {
    return { value: undefined };
  }
  const value = getRowValues(params.api, params.columnApi, params.node);

  return { value: value, api: params.api };
};

//expected return type of any create column function
type CreateColumnReturn<Row extends RowCells> = {
  column: AgColDef;
  validator: Validator<Row> | null;
  pasteOverride: PasteOverride | null;
};

const createValidationColumnFields = <Row extends RowCells>(
  fieldName: string,
  validator: Validator<Row> | null
) => {
  if (isNull(validator)) {
    return EMPTY_OBJECT;
  }
  const validationCols: GridCol = {
    tooltipField: fieldName,
    tooltipComponentParams: {
      validator: validator,
    },
    tooltipComponent: ToolTip,
    tooltipValueGetter: toolTipValueGetter,
    cellStyle: validationColor(validator),
  };

  return validationCols;
};

// build AG Grid select column for our SelectColumn input
const createSelectColumn = <Row extends RowCells>(
  inputCol: SelectColumn<Row>,
  canEdit: boolean,
  disableErrors: boolean
): CreateColumnReturn<Row> => {
  const { fieldName, displayName, defaultable, minWidth, refData, validator, hidden, formatter } =
    inputCol;

  const baseCols: GridCol = {
    headerName: defaultable ? '⚡ ' + displayName : displayName,
    field: inputCol.fieldName as string,
    minWidth,
    editable: (param) => canEdit && !param.node.isRowPinned(),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    cellEditorParams: (param: IRichCellEditorParams) => {
      const rowData = getRowValues(param.api, param.columnApi, param.node);

      return {
        values: inputCol.selectValues(rowData as Row).values,
        cellRenderer: (crParam: any) => (
          <div style={{ width: minWidth }}>{crParam.valueFormatted}</div>
        ),
      };
    },
    initialHide: hidden,
  };

  if (!isNil(refData)) {
    baseCols.refData = refData;
  } else {
    if (!isNil(formatter)) {
      baseCols.valueFormatter = (params: ValueFormatterParams) => {
        const row = params.data;
        const value = formatter.formatOutput(params.value, row);
        return value ?? '';
      };

      baseCols.valueParser = (params: ValueParserParams) => {
        const row = params.data;
        const value = formatter.parseInput(params.newValue, row);
        return value;
      };
    }
  }

  const validationCols = disableErrors ? {} : createValidationColumnFields(fieldName, validator);

  const pasteOverride = inputCol.pasteOverride ?? null;

  const column = { ...baseCols, ...validationCols };
  return { column, validator, pasteOverride };
};

const textComparator = (valueA: string | null, valueB: string | null) => {
  if (isNil(valueA) && isNil(valueB)) {
    return 0;
  }

  if (isNil(valueA)) {
    return 1;
  }

  if (isNil(valueB)) {
    return -1;
  }

  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

// build AG Grid Text column from our TextColumn input
const createTextColumn = <Row extends RowCells>(
  inputCol: TextColumn<Row>,
  canEdit: boolean,
  disableErrors: boolean
): CreateColumnReturn<Row> => {
  const { displayName, fieldName, minWidth, hidden, validator } = inputCol;
  const baseCols: GridCol = {
    headerName: displayName,
    field: fieldName,
    minWidth,
    editable: (params) => !params.node.isRowPinned() && canEdit,
    initialHide: hidden,
    comparator: textComparator,
  };
  const validationCols = disableErrors ? {} : createValidationColumnFields(fieldName, validator);

  const pasteOverride = inputCol.pasteOverride ?? null;

  const column = { ...baseCols, ...validationCols };
  return { column, validator, pasteOverride };
};

// build AG Grid Number Column from our NumberColumn input
const createNumberColumn = <Row extends RowCells>(
  inputCol: NumberColumn<Row>,
  canEdit: boolean,
  disableErrors: boolean
): CreateColumnReturn<Row> => {
  const { displayName, fieldName, minWidth, hidden, validator } = inputCol;
  const baseCols: GridCol = {
    headerName: displayName,
    field: fieldName,
    minWidth: minWidth,
    editable: (params) => !params.node.isRowPinned() && canEdit,
    initialHide: hidden,
    valueParser: (params: ValueParserParams) => {
      return numeral(params.newValue).value();
    },
    valueGetter: (params: ValueGetterParams) => {
      const value = params.data?.[inputCol.fieldName];
      if (!isNil(value)) {
        return toNumber(value);
      }
      return value;
    },
  };

  const formatter =
    inputCol.formatter === 'dollar'
      ? {
          valueFormatter: (params: ValueFormatterParams) =>
            !isNil(params.value) ? numeral(params.value).format('$0,0.00') : '',
        }
      : {};

  const validationCols = disableErrors
    ? {}
    : createValidationColumnFields(inputCol.fieldName, validator);

  const pasteOverride = inputCol.pasteOverride ?? defaultNumberPaste;

  const column = { ...baseCols, ...validationCols, ...formatter };
  return { column, validator, pasteOverride };
};

// build AG Grid Text column from our TextColumn input
const createBooleanColumn = <Row extends RowCells>(
  inputCol: BooleanColumn<Row>,
  canEdit: boolean
): CreateColumnReturn<Row> => {
  const { displayName, fieldName, minWidth, hidden, required, defaultable } = inputCol;
  const baseCols: GridCol = {
    headerName: defaultable ? '⚡ ' + displayName : displayName,
    field: fieldName,
    minWidth: minWidth,
    editable: (params) => !params.node.isRowPinned() && canEdit,
    initialHide: hidden,
    cellEditor: 'agRichSelectCellEditor',
    cellEditorPopup: true,
    valueSetter: (params) => {
      // return bool for if value has changed
      if (isBoolean(params.newValue)) {
        params.data[inputCol.fieldName] = params.newValue;
        return true;
      }

      if (isString(params.newValue)) {
        if (params.newValue.toLowerCase() === 'true') {
          params.data[inputCol.fieldName] = true;
          return true;
        }

        if (params.newValue.toLowerCase() === 'false') {
          params.data[inputCol.fieldName] = false;
          return true;
        }
      }

      return false;
    },
    cellEditorParams: (param: any) => ({
      values: [true, false],
    }),
    refData: { true: 'TRUE', false: 'FALSE' },
  };

  const validator = required
    ? requiredAnd(inputCol.fieldName, (row) => {
        const value = row[inputCol.fieldName];
        return isBoolean(value);
      })
    : (row: RowCells) => {
        const value = row[inputCol.fieldName];
        if (isNil(value) || (!isNil(value) && isBoolean(value))) {
          return { status: Validation.OK, message: null };
        }

        return { status: Validation.ERROR, message: 'invalid value' };
      };
  const validationCols: GridCol = {
    tooltipField: inputCol.fieldName,
    tooltipComponentParams: {
      validator,
    },
    tooltipComponent: ToolTip,
    tooltipValueGetter: toolTipValueGetter,
    cellStyle: validationColor(validator),
  };

  const pasteOverride = inputCol.pasteOverride ?? null;

  const column = { ...baseCols, ...validationCols };
  return { column, validator, pasteOverride };
};

// build AG Grid Date column from our DateColumn input
const createDateColumn = <Row extends RowCells>(
  inputCol: DateColumn<Row>,
  canEdit: boolean,
  disableErrors: boolean
): CreateColumnReturn<Row> => {
  const { displayName, fieldName, minWidth, hidden, validator } = inputCol;

  const baseCols: GridCol = {
    headerName: displayName,
    field: fieldName,
    minWidth: minWidth,
    editable: (params) => !params.node.isRowPinned() && canEdit,
    initialHide: hidden,
    valueParser: (params) => {
      if (isNil(params.newValue)) {
        return null;
      }
      return dayjs(params.newValue);
    },
    valueSetter: (params) => {
      //return bool for if value has changed
      if (isNil(params.newValue)) {
        params.data[inputCol.fieldName] = null;
        return true;
      }

      if (dayjs.isDayjs(params.newValue)) {
        params.data[inputCol.fieldName] = params.newValue;
        return true;
      }

      const newDate = dayjs(params.newValue);
      params.data[inputCol.fieldName] = newDate;
      return true;
    },
    valueFormatter: (params: ValueFormatterParams<Row, Dayjs>) => {
      if (isNil(params.value)) {
        return '';
      }
      if (dayjs.isDayjs(params.value)) {
        return params.value.format('MM/DD/YYYY');
      }
      return dayjs(params.value).format('MM/DD/YYYY');
    },
    valueGetter: (params: ValueGetterParams<RowCells>) => {
      const value = params.data?.[fieldName];
      if (dayjs.isDayjs(value)) {
        return value.format('MM/DD/YYYY');
      }

      return value;
    },
  };
  const validationCols = disableErrors ? {} : createValidationColumnFields(fieldName, validator);

  const column = { ...baseCols, ...validationCols };
  const pasteOverride = inputCol.pasteOverride ?? null;
  return { column, validator, pasteOverride };
};

const createStaticColumn = <Row extends RowCells>(
  inputCol: StaticColumn<Row>
): CreateColumnReturn<Row> => {
  const colDef: GridCol = {
    headerName: inputCol.displayName,
    field: inputCol.fieldName,
    minWidth: inputCol.minWidth,
    initialHide: inputCol.hidden,
    editable: false,
    cellStyle: { background: 'rgba(247,247,247)' },
    valueGetter: (params: ValueGetterParams) => {
      const value = inputCol.value(params.data);
      return value;
    },
  };

  return { column: colDef, validator: null, pasteOverride: null };
};

const createComponentColumn = <Row extends RowCells>(
  inputCol: ComponentColumn<Row>
): CreateColumnReturn<Row> => {
  const colDef: GridCol = {
    headerName: inputCol.displayName,
    field: inputCol.fieldName,
    minWidth: inputCol.minWidth,
    initialHide: inputCol.hidden,
    editable: false,
    cellRenderer: (params: ValueGetterParams) => inputCol.render(params.data),
    valueGetter: (params: ValueGetterParams) => inputCol.value(params.data),
  };

  return { column: colDef, validator: null, pasteOverride: null };
};

export const autoSizeAll = (gridColumnApi: any) => {
  const allColumnIds: any = [];
  gridColumnApi.getAllColumns().forEach(function (column: any) {
    allColumnIds.push(column.colId);
  });
  gridColumnApi.autoSizeColumns(allColumnIds, false);
};

export type UploadColName = string;

export type UploadMappingFunction<Row extends RowCells> = (
  rowValue: string | number | boolean | null | undefined
) =>
  | {
      colName: keyof Row;
      newValue: CellValue;
    }
  | Array<{
      colName: keyof Row;
      newValue: CellValue;
    }>;

export type UploadMapping<Row extends RowCells> = {
  [key: UploadColName]: UploadMappingFunction<Row>;
};

const parseCSV = async (file: File) => {
  const XLSX = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
  const fileData = await file.arrayBuffer();
  const parsed = XLSX.read(fileData, { type: 'array', cellDates: true });
  const sheetOne = parsed.SheetNames[0];

  const output = XLSX.utils.sheet_to_json(parsed.Sheets[sheetOne]);
  return output as { [key: string]: string }[];
};

export type ActionButton = {
  label: string;
  icon?: React.ReactNode;
  onClick: (gridApi: GridApi<any>, columnApi: ColumnApi) => Promise<void>;
};

export type GridValidation = { message: string; level: Validation };

export type InputGridProps<Row extends RowCells, Column extends ColDef<Row>> = {
  tableName: ReactNode;
  columns: Column[];
  rows: Row[];
  onSubmit?: (data: Row[]) => Promise<void> | void;
  canEdit: boolean;
  disableErrors?: boolean;
  defaultRow?: (row: Row) => Row;
  defaultRowBuilder?: (gridApi: GridApi<any>) => (Row: Row) => Row;
  defaultActionLabel?: string;
  uploadMapping?: UploadMapping<Row>;
  actions?: React.ReactNode;
  pinnedBottomRowData?: (data: Row[]) => Partial<Row>[];
  autoHeight?: boolean;
  primaryActionButtons?: Array<ActionButton>;
  secondaryActionButtons?: Array<ActionButton>;
  skipValidateOnSubmit?: boolean;
  saveButtonLabel?: string;
  gridValidations?: (data: Row[]) => GridValidation[];
  isUploadPdfEnabled?: boolean;
  disableSubmitButton?: boolean;
  disableUploadButton?: boolean;
  disableErrorColumn?: boolean;
  uploadPdfMetadata?: {
    quoteId?: string;
  };
  isFullScreen?: boolean;
  heightOverride?: string; // if you use this, set autoHeight to false
};

const InputGrid = <Row extends RowCells, Column extends ColDef<Row>>({
  tableName,
  columns,
  onSubmit,
  canEdit,
  defaultRow,
  defaultRowBuilder,
  defaultActionLabel,
  uploadMapping,
  actions,
  pinnedBottomRowData,
  primaryActionButtons,
  secondaryActionButtons,
  autoHeight = false,
  skipValidateOnSubmit = true,
  saveButtonLabel = 'Submit',
  gridValidations,
  isUploadPdfEnabled = false,
  disableUploadButton = false,
  disableSubmitButton = false,
  disableErrorColumn = false,
  disableErrors = false,
  isFullScreen = false,
  uploadPdfMetadata,
  heightOverride = undefined,
  ...rest
}: InputGridProps<Row, Column>) => {
  //https://www.ag-grid.com/react-data-grid/react-hooks/#:~:text=If%20you%20do%20NOT%20use,such%20as%20row%20selection%20resetting.
  // ag grid wants data in in state to prevent rerenders
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [rows, _setRows] = useState(rest.rows);
  //state if any rows are selected
  const [isSelected, setSelected] = useState(false);
  //state of grid save status
  const [saved, setSaved] = useState(true);
  // grid level validation messages to display
  const [gridWarnings, setGridWarnings] = useState<GridValidation[]>([]);
  const gridRef = useRef<AgGridReact>(null);

  const { columnDefs, pasteOverrides } = useMemo(() => {
    const inputColumns = columns.map((col) => {
      switch (col.inputType) {
        case InputType.SELECT: {
          return createSelectColumn(col, canEdit, disableErrors);
        }
        case InputType.TEXT: {
          return createTextColumn(col, canEdit, disableErrors);
        }
        case InputType.NUMBER: {
          return createNumberColumn(col, canEdit, disableErrors);
        }
        case InputType.BOOLEAN: {
          return createBooleanColumn(col, canEdit);
        }
        case InputType.DATE: {
          return createDateColumn(col, canEdit, disableErrors);
        }
        case InputType.STATIC: {
          return createStaticColumn(col);
        }
        case InputType.COMPONENT: {
          return createComponentColumn(col);
        }
      }
    });

    const baseColumns: GridOptions['columnDefs'] = [
      {
        headerName: 'Row #',
        valueGetter: 'node.rowIndex + 1',
        width: 70,
        pinned: 'left',
        filter: false,
        hide: disableErrorColumn === true,
        cellStyle: { background: 'rgba(247,247,247)' },
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        suppressMovable: true,
        suppressPaste: true,
        editable: false,
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: () => {
                return null;
              },
            };
          } else {
            return undefined;
          }
        },
      },
      {
        //This columns is an empty column with the select checkbox for the row
        width: 50,
        editable: false,
        pinned: 'left',
        suppressPaste: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        suppressMovable: true,
        hide: canEdit ? false : true,
      },
      {
        headerName: 'Error',
        colId: 'error',
        width: 70,
        hide: canEdit === true && disableErrorColumn === false ? false : true,
        editable: false,
        suppressPaste: true,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        valueGetter: (params) => {
          const validations: Validation[] = [];
          inputColumns.forEach((col) => {
            if (!isNull(col.validator)) {
              const { status } = col.validator(params.data, params.api);
              validations.push(status);
            }
          });
          const isError = validations.some((val) => val === Validation.ERROR);

          if (isError) {
            return Validation.ERROR;
          }
          const isWarning = validations.some((val) => val === Validation.WARNING);
          if (isWarning) {
            return Validation.WARNING;
          }

          return Validation.OK;
        },
        cellRendererSelector: (params) => {
          if (params.node.rowPinned) {
            return {
              component: () => {
                return null;
              },
            };
          } else {
            return {
              component: (p: any) => {
                const v = p.getValue();

                if (v === Validation.ERROR) {
                  return <AlertCircle width={16} height={16} />;
                }

                return null;
              },
            };
          }
        },

        pinned: 'left',
      },
    ];

    const pasteOverridesByColumn = inputColumns.reduce<Record<string, PasteOverride>>(
      (acc, col) => {
        if (col.pasteOverride && col.column.field) {
          acc[col.column.field] = col.pasteOverride;
        }
        return acc;
      },
      {}
    );

    const builtColumns = inputColumns.map((col) => col.column);
    return {
      columnDefs: [...baseColumns, ...builtColumns],
      pasteOverrides: pasteOverridesByColumn,
    };
  }, [canEdit, columns]);

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      editable: canEdit,
      sortable: true,
      flex: 1,
      rowSelection: 'multiple',
      filter: 'agTextColumnFilter',
      // pass in additional parameters to the text filter
      filterParams: {
        buttons: ['reset', 'apply', 'clear', 'cancel'],
        debounceMs: 200,
      },
    }),
    [canEdit]
  );

  const checkRowValid = (node: IRowNode<any>): Validation => {
    return gridRef.current!.api.getValue('error', node);
  };

  const getRows = useCallback(() => {
    const outputData: Row[] = [];

    gridRef.current?.api?.forEachNode(function (node) {
      outputData.push(node.data);
    });

    return outputData;
  }, []);

  const [rowCount, setRowCount] = useState(getRows().length);

  const setGridValidations = useCallback(() => {
    if (gridValidations) {
      const validations = gridValidations(getRows());
      setGridWarnings(validations);
    }
  }, [getRows, gridValidations]);

  const setPinnedBottomRowData = useCallback(() => {
    if (pinnedBottomRowData) {
      gridRef.current?.api?.setPinnedBottomRowData(pinnedBottomRowData(getRows()));
    }
  }, [getRows, pinnedBottomRowData]);

  const addRow = useCallback(() => {
    gridRef.current!.api.applyTransaction({
      add: [{}],
      addIndex: 0,
    })!;
    setSaved(false);
  }, []);

  // clear entire grid
  const clearData = useCallback(() => {
    const d: any[] = [];
    gridRef.current!.api.forEachNode(function (node) {
      d.push(node.data);
    });

    gridRef.current!.api.applyTransaction({
      remove: d,
    })!;
    setSaved(false);
  }, []);

  const removeSelected = useCallback(() => {
    const selectedData = gridRef.current!.api.getSelectedRows();
    gridRef.current!.api.applyTransaction({
      remove: selectedData,
    })!;
    setPinnedBottomRowData();
    setSaved(false);
  }, [setPinnedBottomRowData]);

  const duplicateSelected = useCallback(() => {
    const selectedData = gridRef.current!.api.getSelectedNodes();

    // iterate over current selection, copy data and find greatest index
    let addIndex = 0;
    const duplicated: any[] = [];
    selectedData.forEach((node) => {
      duplicated.push({ ...node.data });
      const idx = node.rowIndex ?? 0;
      if (idx ?? 0 > addIndex) {
        addIndex = idx === 0 ? idx : idx + 1;
      }
    });

    // add data at greatest index (lowest row on grid)
    gridRef.current!.api.applyTransaction({
      add: duplicated,
      addIndex,
    })!;
    setSaved(false);
  }, []);

  // any functions to call once the grid is ready should be here
  const onGridReady = (params: GridReadyEvent) => {
    autoSizeAll(params.columnApi);

    if (isEmpty(rows) && canEdit) {
      addRow();
    }

    setPinnedBottomRowData();
    setGridValidations();
  };

  // map default row functions to each row
  const setDefaultValues = useCallback(() => {
    const toSetDefault: Row[] = [];
    let defaultRowFunction = defaultRow;
    if (!isNil(defaultRowBuilder)) {
      defaultRowFunction = defaultRowBuilder(gridRef.current!.api);
    }

    if (isNil(defaultRowFunction)) {
      return;
    }

    gridRef.current!.api.forEachNode(function (node) {
      const row = getRowValues(gridRef.current!.api, gridRef.current!.columnApi, node) as Row;
      const newRow = defaultRowFunction!(row);

      // need to preserve reference to original row and update in place
      Object.entries(newRow).forEach(([key, value]) => {
        node.data[key] = value;
      });
      toSetDefault.push(node.data);
    });

    gridRef.current!.api.applyTransaction({
      update: toSetDefault,
    })!;
    setSaved(false);
  }, [defaultRow, defaultRowBuilder]);

  const onExportExcel = useCallback(() => {
    gridRef.current!.api.exportDataAsExcel({ skipPinnedBottom: true, skipPinnedTop: true });
  }, []);

  const validateAndSubmit = async () => {
    const validations: unknown[] = [];
    const outputData: unknown[] = [];

    const allColumns = gridRef.current!.columnApi.getColumns();

    // iterate over all rows
    gridRef.current!.api.forEachNode(function (node) {
      // validate each row
      validations.push(checkRowValid(node));

      // iterate over each column in row and retrieve value via any value getter
      const row: Record<string, unknown> = {};
      allColumns?.forEach((col) => {
        const id = col.getColId();
        // must apply value getters
        const value = gridRef.current?.api.getValue(id, node);
        row[id] = value;
      });
      outputData.push(row);
    });

    if (!skipValidateOnSubmit) {
      if (validations.some((v) => v === Validation.ERROR)) {
        showNotification({ message: 'Please Fix Rows Before Continuing', color: 'red' });
        return;
      }
    }

    if (!isNil(onSubmit)) {
      await onSubmit(outputData as Row[]);
    }
    setSaved(true);
    showNotification({ message: 'Saved!', color: 'green' });
  };

  const onUpload = async (value: File | null) => {
    if (isNil(value)) {
      return;
    }
    gridRef.current!.api.showLoadingOverlay();
    clearData();
    const csv = await parseCSV(value);

    const mostLikelyColumnNameMapping: Record<string, string> = {};
    if (isNil(uploadMapping)) {
      throw new Error('Upload Mapping is undefined');
    }
    const uploadColNames = Object.keys(uploadMapping);
    const outputRows: Row[] = csv.reduce<Row[]>((aggRows, row) => {
      //apply mapping function to each row in csv
      // if all fields are undefined consider row empty
      let emptyRow = true;
      const newRow: any = {};
      Object.entries(row).forEach(([k, v]) => {
        // @mo: I HATE THIS. RIP
        let mostLikelyColumnName = 'EMPTY';

        if (mostLikelyColumnNameMapping[k]) {
          mostLikelyColumnName = mostLikelyColumnNameMapping[k];
        } else {
          // fuzzy match csv column against upload mapping columns
          const matches = extract(k, uploadColNames);
          const matchScore = matches[0][1];

          if (matchScore < 70) {
            console.warn(`No fuzzy match found for ${k}`);
            // @mo: I HATE THIS too
            mostLikelyColumnNameMapping[k] = 'EMPTY';
            return;
          }

          mostLikelyColumnName = matches[0][0] as string;

          mostLikelyColumnNameMapping[k] = mostLikelyColumnName;
        }

        if (isNil(uploadMapping)) {
          throw new Error('Upload Mapping is undefined');
        }
        const fn = uploadMapping[mostLikelyColumnName];
        if (!isNil(fn)) {
          const uploadMappingOutput = fn(v);
          // if array add each mapping output
          if (Array.isArray(uploadMappingOutput)) {
            uploadMappingOutput.forEach((mapping) => {
              const { colName, newValue } = mapping;
              newRow[colName] = newValue;
              if (!isNil(newValue)) {
                emptyRow = false;
              }
            });
          } else {
            const { colName, newValue } = uploadMappingOutput;
            newRow[colName] = newValue;
            if (!isNil(newValue)) {
              emptyRow = false;
            }
          }
        }
      });
      // if empty object or all fields undefined/null
      if (isEmpty(newRow) || emptyRow) {
        return aggRows;
      }
      return [...aggRows, newRow];
    }, []);

    // async transaction improves performance for massive row counts
    // allows grid to start rendering as data is added
    gridRef.current!.api.applyTransactionAsync(
      {
        add: outputRows,
        addIndex: 0,
      },
      () => {
        showNotification({ message: 'Upload complete' });
        console.info('Upload complete');
        setSaved(false);
        gridRef.current!.api.hideOverlay();
      }
    )!;
  };

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
    };
  }, []);

  // Influenced by https://www.ag-grid.com/react-data-grid/clipboard/#pasting-new-rows-at-the-bottom-of-the-grid
  // modified to allow custom copy paste callback by for each column
  const processDataFromClipboard = useCallback(
    (params: ProcessDataFromClipboardParams): string[][] | null => {
      //copy data from clipboard
      const data = [...params.data];
      const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1;
      if (emptyLastRow) {
        data.splice(data.length - 1, 1);
      }
      //Find last index and the index of the currently selected cell
      const lastIndex = gridRef.current!.api.getModel().getRowCount() - 1;
      const focusedCell = gridRef.current!.api.getFocusedCell();
      const focusedIndex = focusedCell!.rowIndex;

      // if there is more data to paste then there is space in the grid
      // create the new empty rows and add them to the grid
      if (focusedIndex + data.length - 1 > lastIndex) {
        const resultLastIndex = focusedIndex + (data.length - 1);
        const numRowsToAdd = resultLastIndex - lastIndex;
        const rowsToAdd: RowCells[] = [];
        for (let i = 0; i < numRowsToAdd; i++) {
          const index = data.length - 1;
          const row = data.slice(index, index + 1)[0];
          // Create row object
          const rowObject: RowCells = {};
          let currentColumn: CellPosition['column'] | null = focusedCell!.column;
          row.forEach((item) => {
            if (!currentColumn?.getColId()) {
              return;
            }
            rowObject[currentColumn?.getColId()] = undefined;
            // find next column
            currentColumn = gridRef.current!.columnApi.getDisplayedColAfter(currentColumn);
          });
          //add new row to cumulative list of rows to add
          rowsToAdd.push(rowObject);
        }
        // add new rows to grid
        gridRef.current!.api.applyTransaction({ add: rowsToAdd });
      }

      // return all currently displayed columns in order
      const displayedColumns = gridRef.current!.columnApi.getAllDisplayedColumns();

      // find the index of the column of the currently selected cell
      const startIdx = displayedColumns.findIndex(
        (col) => col.getColId() === focusedCell!.column.getColId()
      );

      // iterate over the matrix of data and apply the pasteOverride callback for each column if provided
      const newData = data.map((row, rowIdx) =>
        row.map((column, idx) => {
          // find the current col def by offsetting the startIdx by the current index
          const currentColumn = displayedColumns[startIdx + idx];
          //initialize itemToAdd to raw input value
          let itemToAdd = column;

          //search for a paste override callback and apply if found
          const columnPasteOverride = pasteOverrides?.[currentColumn.getColId()];
          if (!isNil(columnPasteOverride)) {
            const currentRow = gridRef.current!.api.getDisplayedRowAtIndex(focusedIndex + rowIdx);
            itemToAdd = columnPasteOverride(column, { ...(currentRow?.data ?? EMPTY_OBJECT) });
          }
          return itemToAdd;
        })
      );
      return newData;
    },
    [pasteOverrides]
  );
  const divHeight = autoHeight ? '100%' : '90vh';

  return (
    <div
      style={{
        height: heightOverride ? heightOverride : divHeight,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ marginBottom: '7px' }}>
        <Group justify="space-between" mb="lg">
          <Group>
            <Title order={5}>{tableName}</Title>

            {canEdit && disableSubmitButton === false && (
              <>
                {saved ? (
                  <Badge color="green">saved</Badge>
                ) : (
                  <Badge color="red">unsaved changes</Badge>
                )}
              </>
            )}
            {gridWarnings.map((item) => (
              <Badge key={item.message} color="red">
                {item.message}
              </Badge>
            ))}
          </Group>
          <Group gap="xs" justify="right">
            {actions}
            {isSelected && canEdit && (
              <>
                <Button
                  onClick={duplicateSelected}
                  leftSection={<Duplicate width={16} height={16} />}
                  size="xs"
                  variant="outline"
                >
                  Duplicate
                </Button>
              </>
            )}
            {!isNil(primaryActionButtons) &&
              primaryActionButtons.map((button) => (
                <Button
                  disabled={!canEdit}
                  leftSection={button.icon}
                  key={button.label}
                  onClick={async () =>
                    button.onClick(gridRef.current!.api, gridRef.current!.columnApi)
                  }
                  size="xs"
                  variant="outline"
                >
                  {button.label}
                </Button>
              ))}
            {(!isNil(defaultRow) || !isNil(defaultRowBuilder)) && canEdit && (
              <Button
                leftSection={<Flash width={16} height={16} />}
                onClick={setDefaultValues}
                size="xs"
                variant="outline"
              >
                {defaultActionLabel ? defaultActionLabel : 'Fill Defaults'}
              </Button>
            )}
            {isUploadPdfEnabled && rowCount > 10 && isFullScreen === true && (
              <Button
                onClick={onExportExcel}
                leftSection={<ArrowDownCircle width={16} height={16} />}
                size="xs"
                variant="outline"
              >
                Download Excel
              </Button>
            )}
            {!isNil(secondaryActionButtons) && (
              <Menu trigger={'hover'} shadow="sm" disabled={!canEdit}>
                <Menu.Target>
                  <Button
                    variant="outline"
                    size="xs"
                    rightSection={<ChevronDown width={16} height={16} />}
                  >
                    Actions
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  {secondaryActionButtons.map((button) => (
                    <Menu.Item
                      key={button.label}
                      leftSection={button.icon}
                      onClick={async () =>
                        button.onClick(gridRef.current!.api, gridRef.current!.columnApi)
                      }
                    >
                      {button.label}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            )}
            {canEdit && disableSubmitButton === false && (
              <Button
                onClick={validateAndSubmit}
                disabled={!canEdit}
                size="xs"
                data-testid="input-grid-submit"
              >
                {saveButtonLabel}
              </Button>
            )}
          </Group>
        </Group>
      </div>
      <div
        style={{
          width: '100%',
          height: heightOverride ? heightOverride : '100%',
        }}
        className="ag-theme-balham"
      >
        <AgGridReact
          domLayout={autoHeight ? 'autoHeight' : undefined}
          rowBuffer={100}
          sideBar={sideBar}
          processDataFromClipboard={processDataFromClipboard}
          valueCache={false}
          ref={gridRef}
          rowData={rows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          rowSelection={'multiple'}
          animateRows={true}
          stopEditingWhenCellsLoseFocus={true}
          onSortChanged={(param) => param.api.refreshCells()}
          onCellValueChanged={(param) => {
            param.api.redrawRows({ rowNodes: [param.node] });

            setPinnedBottomRowData();
            setGridValidations();
            setSaved(false);
          }}
          tooltipShowDelay={0}
          tooltipHideDelay={2000}
          rowHeight={25}
          onSelectionChanged={(c) => {
            const rl = c.api.getSelectedRows().length;
            if (rl) {
              setSelected(true);
            } else {
              setSelected(false);
            }
          }}
          enableRangeSelection={true}
          clipboardDeliminator={','}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={5}
          suppressCopySingleCellRanges={true}
          suppressRowClickSelection={true}
        />
      </div>
      <div style={{ marginTop: '7px' }}>
        <Group data-testid={`${tableName} Buttons`}>
          {canEdit && (
            <>
              {disableUploadButton === false && (
                <FileButton accept="text/csv" onChange={async (value) => onUpload(value)}>
                  {(props) => (
                    <Button
                      {...props}
                      leftSection={<CloudUpload width={16} height={16} />}
                      size="xs"
                      variant="outline"
                    >
                      Upload
                    </Button>
                  )}
                </FileButton>
              )}
              <Button
                leftSection={<AddCircle width={16} height={16} />}
                onClick={addRow}
                size="xs"
                variant="outline"
              >
                Add Row
              </Button>
              <Button
                onClick={removeSelected}
                leftSection={<Trash width={16} height={16} />}
                size="xs"
                variant="outline"
              >
                Delete Row
              </Button>
              <Button
                onClick={clearData}
                leftSection={<CloseCircle width={16} height={16} />}
                size="xs"
                variant="outline"
              >
                Clear All
              </Button>
            </>
          )}
          <Button
            onClick={onExportExcel}
            leftSection={<ArrowDownCircle width={16} height={16} />}
            size="xs"
            variant="outline"
          >
            Download Excel
          </Button>
          {isUploadPdfEnabled && uploadPdfMetadata && (
            <Button
              onClick={() =>
                openModal({
                  modalId: 'uploadLossRunModal',
                  title: 'Upload Loss Run',
                  children: (
                    <UploadLossRunFileModal quoteId={uploadPdfMetadata?.quoteId as string} />
                  ),
                  size: rem(500),
                })
              }
              leftSection={<Sparkles width={16} height={16} />}
              size="xs"
              variant="outline"
            >
              Upload Loss Run
            </Button>
          )}
        </Group>
      </div>
    </div>
  );
};

export default InputGrid;
