export const namedOperations = {
  Query: {
    ExcessAutoManualRatings: 'ExcessAutoManualRatings',
    ExcessAutoRiskTier: 'ExcessAutoRiskTier',
    ExcessGeneralLiabilityManualRatings: 'ExcessGeneralLiabilityManualRatings',
    ExcessAutoCoverageSelections: 'ExcessAutoCoverageSelections',
    PrimaryAutoManualRatings: 'PrimaryAutoManualRatings',
    PrimaryGeneralLiabilityManualRatings: 'PrimaryGeneralLiabilityManualRatings',
    PrimaryProjectAutoManualRatings: 'PrimaryProjectAutoManualRatings',
    PrimaryProjectAutoRiskTier: 'PrimaryProjectAutoRiskTier',
    PrimaryProjectDetails: 'PrimaryProjectDetails',
    ProjectExcessDetails: 'ProjectExcessDetails',
    AllDefaultAutoData: 'AllDefaultAutoData',
    DefaultAutoData: 'DefaultAutoData',
    DefaultAutoZipCodesByMostCommonPayrollTerritory:
      'DefaultAutoZipCodesByMostCommonPayrollTerritory',
    DefaultAutoZipCodesByState: 'DefaultAutoZipCodesByState',
    HqZipCode: 'HqZipCode',
    ExposureNotes: 'ExposureNotes',
    ExposureNotesForQuote: 'ExposureNotesForQuote',
    LicenseChecks: 'LicenseChecks',
    FeesPerQuote: 'FeesPerQuote',
    PolicyChangeEndorsements: 'PolicyChangeEndorsements',
    PolicyFilterOptions: 'PolicyFilterOptions',
    MyOpenPolicies: 'MyOpenPolicies',
    PaginatedPolicies: 'PaginatedPolicies',
    PrimaryAutoVehicles: 'PrimaryAutoVehicles',
    PrimaryAutoCoverageOptions: 'PrimaryAutoCoverageOptions',
    PrimaryAutoCoverageOptionsStates: 'PrimaryAutoCoverageOptionsStates',
    PrimaryAutoVehicleSummary: 'PrimaryAutoVehicleSummary',
    PrimaryUnderwritingAdjustments: 'PrimaryUnderwritingAdjustments',
    UserHistoryShepsy: 'UserHistoryShepsy',
    UnmannedAircraft: 'UnmannedAircraft',
    UnmannedAircraftConstants: 'UnmannedAircraftConstants',
    SelfAudits: 'SelfAudits',
    SelfAudit: 'SelfAudit',
    AutoExposure: 'AutoExposure',
    AutoFleetSummary: 'AutoFleetSummary',
    ExpectedPayrollTableConstants: 'ExpectedPayrollTableConstants',
    ExpectedSubcontractedCostTableConstants: 'ExpectedSubcontractedCostTableConstants',
    VehicleFleetTableConstants: 'VehicleFleetTableConstants',
    AutoRiskInputTableConstants: 'AutoRiskInputTableConstants',
    CoverageOptionsConstants: 'CoverageOptionsConstants',
    NaicsClassCodesConstants: 'NaicsClassCodesConstants',
    LicensedStates: 'LicensedStates',
    ContainmentFactors: 'ContainmentFactors',
    ProgramTypes: 'ProgramTypes',
    CostOfHires: 'CostOfHires',
    ExcessAutoVehicles: 'ExcessAutoVehicles',
    ExcessAutoCoverageOptions: 'ExcessAutoCoverageOptions',
    ExcessAutoVehicleSummary: 'ExcessAutoVehicleSummary',
    ExpectedPayrolls: 'ExpectedPayrolls',
    Files: 'Files',
    FormPdf: 'FormPdf',
    FormPdfs: 'FormPdfs',
    FormPdfsNotOnGroup: 'FormPdfsNotOnGroup',
    FormGroup: 'FormGroup',
    FormGroupNames: 'FormGroupNames',
    GeneralLiabilityExposure: 'GeneralLiabilityExposure',
    Insights: 'Insights',
    InsightsLink: 'InsightsLink',
    InsuranceProducts: 'InsuranceProducts',
    PolicyholderInvoices: 'PolicyholderInvoices',
    PolicyInvoices: 'PolicyInvoices',
    InvoiceDownloadUrl: 'InvoiceDownloadUrl',
    LargeLossRun: 'LargeLossRun',
    LargeLossRunPhysDam: 'LargeLossRunPhysDam',
    Claims: 'Claims',
    LossRunFiles: 'LossRunFiles',
    LossRunTpaConstants: 'LossRunTpaConstants',
    LossRunCarriersConstants: 'LossRunCarriersConstants',
    LossRun: 'LossRun',
    LossRunPhysDam: 'LossRunPhysDam',
    MarketplaceOffers: 'MarketplaceOffers',
    OrganizationIntegrations: 'OrganizationIntegrations',
    ProcoreCompanies: 'ProcoreCompanies',
    AutodeskHubs: 'AutodeskHubs',
    BrokerageLocation: 'BrokerageLocation',
    BrokerageLocations: 'BrokerageLocations',
    OrganizationUsers: 'OrganizationUsers',
    OrganizationMembers: 'OrganizationMembers',
    Brokerages: 'Brokerages',
    Brokerage: 'Brokerage',
    Carriers: 'Carriers',
    Carrier: 'Carrier',
    PolicyHolderPolicies: 'PolicyHolderPolicies',
    Policy: 'Policy',
    PolicyFiles: 'PolicyFiles',
    Policies: 'Policies',
    PolicyClearanceResults: 'PolicyClearanceResults',
    PolicyFilesStructure: 'PolicyFilesStructure',
    policyFillData: 'policyFillData',
    PolicyHoldersV3: 'PolicyHoldersV3',
    PolicyHoldersFilterOptions: 'PolicyHoldersFilterOptions',
    PolicyholdersNames: 'PolicyholdersNames',
    Policyholder: 'Policyholder',
    QuotePricing: 'QuotePricing',
    QuoteRiskTier: 'QuoteRiskTier',
    QuotePricingDebug: 'QuotePricingDebug',
    LossDevelopmentModelConfigs: 'LossDevelopmentModelConfigs',
    PrimaryGeneralLiabilityConstants: 'PrimaryGeneralLiabilityConstants',
    PrimaryOcpConstants: 'PrimaryOcpConstants',
    PrimaryOcpExposure: 'PrimaryOcpExposure',
    PrimaryRailroadConstants: 'PrimaryRailroadConstants',
    PrimaryRailroadExposure: 'PrimaryRailroadExposure',
    ProfitAndLossReports: 'ProfitAndLossReports',
    QuoteActivities: 'QuoteActivities',
    QuotePdfs: 'QuotePdfs',
    Quote: 'Quote',
    Quotes: 'Quotes',
    PolicyNumbersToDisplayForQuote: 'PolicyNumbersToDisplayForQuote',
    DigitalQuote: 'DigitalQuote',
    ReferralTriggers: 'ReferralTriggers',
    SelectedForms: 'SelectedForms',
    AvailableFormsForQuote: 'AvailableFormsForQuote',
    SubcontractedCost: 'SubcontractedCost',
    QuoteSubjectivities: 'QuoteSubjectivities',
    SubjectivitiesConstants: 'SubjectivitiesConstants',
    SentryError: 'SentryError',
    Health: 'Health',
    UnderlyingPolicies: 'UnderlyingPolicies',
    UnderlyingPolicyAttachmentOrderOptions: 'UnderlyingPolicyAttachmentOrderOptions',
    UnderlyingPolicyFiles: 'UnderlyingPolicyFiles',
    Session: 'Session',
    ShepherdAdminUsers: 'ShepherdAdminUsers',
    PaginatedUsers: 'PaginatedUsers',
    Users: 'Users',
    WorkersCompensationExposure: 'WorkersCompensationExposure',
  },
  Mutation: {
    UpdateExcessGeneralLiabilityManualRatings: 'UpdateExcessGeneralLiabilityManualRatings',
    UpdatePrimaryGeneralLiabilityManualRatings: 'UpdatePrimaryGeneralLiabilityManualRatings',
    UpdatePrimaryProjectAutoManualRatings: 'UpdatePrimaryProjectAutoManualRatings',
    CreateBinderPdf: 'CreateBinderPdf',
    UpdateDefaultAutoData: 'UpdateDefaultAutoData',
    UpsertExposureNote: 'UpsertExposureNote',
    DeleteExposureNoteMutation: 'DeleteExposureNoteMutation',
    RunLicenseChecks: 'RunLicenseChecks',
    GenerateLicensingPdf: 'GenerateLicensingPdf',
    AddressAgentSyncCheck: 'AddressAgentSyncCheck',
    createPolicyChangeEndorsement: 'createPolicyChangeEndorsement',
    DeletePolicyChangeEndorsement: 'DeletePolicyChangeEndorsement',
    UpdatePolicyChangeEndorsement: 'UpdatePolicyChangeEndorsement',
    IssuePolicyPdf: 'IssuePolicyPdf',
    IssuePolicySpecimenPdf: 'IssuePolicySpecimenPdf',
    GenerateDrivePolicyDocs: 'GenerateDrivePolicyDocs',
    MergeDrivePolicyDocs: 'MergeDrivePolicyDocs',
    EmptyPolicyDriveFolder: 'EmptyPolicyDriveFolder',
    GenerateAutoPacket: 'GenerateAutoPacket',
    UpdatePrimaryAutoVehicles: 'UpdatePrimaryAutoVehicles',
    UpdatePrimaryAutoCoverageOptions: 'UpdatePrimaryAutoCoverageOptions',
    CreatePrimaryAutoCoverageOption: 'CreatePrimaryAutoCoverageOption',
    SeedPrimaryAutoFleet: 'SeedPrimaryAutoFleet',
    UpdatePrimaryUnderwritingAdjustments: 'UpdatePrimaryUnderwritingAdjustments',
    UpdateSelectedContainmentFactor: 'UpdateSelectedContainmentFactor',
    AskShepsy: 'AskShepsy',
    UpdateFeedbackShepsy: 'UpdateFeedbackShepsy',
    UpdateUnmannedAircraft: 'UpdateUnmannedAircraft',
    UpdateSelfAudit: 'UpdateSelfAudit',
    RunSelfAuditAutoDetection: 'RunSelfAuditAutoDetection',
    AutoVinData: 'AutoVinData',
    UpdateCostOfHires: 'UpdateCostOfHires',
    UpdateExcessAutoExposure: 'UpdateExcessAutoExposure',
    UpdateExcessAutoExposureAndScheduleRatings: 'UpdateExcessAutoExposureAndScheduleRatings',
    UpdateExcessAutoVehicles: 'UpdateExcessAutoVehicles',
    UpdateExcessAutoCoverageOptions: 'UpdateExcessAutoCoverageOptions',
    UpdateExcessAutoCoverageOption: 'UpdateExcessAutoCoverageOption',
    UpdateExcessGeneralLiabilityExposure: 'UpdateExcessGeneralLiabilityExposure',
    UpdateExpectedPayroll: 'UpdateExpectedPayroll',
    CreateFile: 'CreateFile',
    CreateFileWithUnderlyingPolicies: 'CreateFileWithUnderlyingPolicies',
    UpdateFile: 'UpdateFile',
    DeleteFile: 'DeleteFile',
    FormDownloadUrl: 'FormDownloadUrl',
    DownloadFormGroupsWithForms: 'DownloadFormGroupsWithForms',
    CreateFormPdf: 'CreateFormPdf',
    UpdateFormPdf: 'UpdateFormPdf',
    DuplicateFormPdf: 'DuplicateFormPdf',
    DeleteFormPdf: 'DeleteFormPdf',
    UpdateFormPdfTags: 'UpdateFormPdfTags',
    CreateFormGroup: 'CreateFormGroup',
    UpdateFormGroup: 'UpdateFormGroup',
    DeleteFormGroup: 'DeleteFormGroup',
    CreateInvoice: 'CreateInvoice',
    UpdateInvoice: 'UpdateInvoice',
    DeleteInvoice: 'DeleteInvoice',
    UpdateLargeLossRun: 'UpdateLargeLossRun',
    UpdateLargeLossRunPhysDam: 'UpdateLargeLossRunPhysDam',
    UploadAndParseLossRunFileToClaims: 'UploadAndParseLossRunFileToClaims',
    UpdateLossRun: 'UpdateLossRun',
    UpdateLossRunPhysDam: 'UpdateLossRunPhysDam',
    RedeemMarketplaceOffer: 'RedeemMarketplaceOffer',
    RunOfacClearance: 'RunOfacClearance',
    UpdateOrganizationIntegration: 'UpdateOrganizationIntegration',
    CreateOrganizationIntegration: 'CreateOrganizationIntegration',
    DeleteOrganizationIntegration: 'DeleteOrganizationIntegration',
    CreateOrganizationLocation: 'CreateOrganizationLocation',
    UpdateOrganizationLocation: 'UpdateOrganizationLocation',
    DeleteOrganizationLocation: 'DeleteOrganizationLocation',
    CreateOrganization: 'CreateOrganization',
    UpdateOrganization: 'UpdateOrganization',
    CreateOrganizationUser: 'CreateOrganizationUser',
    DeleteOrganizationUser: 'DeleteOrganizationUser',
    DeleteOrganization: 'DeleteOrganization',
    UpdateOrganizationUser: 'UpdateOrganizationUser',
    RenewPolicy: 'RenewPolicy',
    RenewNonIssuedPolicy: 'RenewNonIssuedPolicy',
    CreatePolicy: 'CreatePolicy',
    UpdatePolicy: 'UpdatePolicy',
    DeletePolicy: 'DeletePolicy',
    ClearPolicy: 'ClearPolicy',
    CreatePolicyholder: 'CreatePolicyholder',
    UpdatePolicyholder: 'UpdatePolicyholder',
    DeletePolicyholder: 'DeletePolicyholder',
    CreatePolicyholderSlackChannel: 'CreatePolicyholderSlackChannel',
    GenerateCaseStudy: 'GenerateCaseStudy',
    CreatePricingExcel: 'CreatePricingExcel',
    DownloadPricingExcel: 'DownloadPricingExcel',
    UpdatePrimaryAutoExposure: 'UpdatePrimaryAutoExposure',
    UpdatePrimaryGeneralLiabilityExposure: 'UpdatePrimaryGeneralLiabilityExposure',
    UpdatePrimaryOcpExposure: 'UpdatePrimaryOcpExposure',
    UpdatePrimaryRailroadExposure: 'UpdatePrimaryRailroadExposure',
    createProcoreUsage: 'createProcoreUsage',
    deleteProcoreUsage: 'deleteProcoreUsage',
    updateProcoreUsage: 'updateProcoreUsage',
    CreateProfitAndLossReport: 'CreateProfitAndLossReport',
    UpdateProjectExcessGeneralLiabilityExposure: 'UpdateProjectExcessGeneralLiabilityExposure',
    UpdateProjectPrimaryAutoExposure: 'UpdateProjectPrimaryAutoExposure',
    UpdateProjectPrimaryGeneralLiabilityExposure: 'UpdateProjectPrimaryGeneralLiabilityExposure',
    CreateCommentQuoteActivity: 'CreateCommentQuoteActivity',
    CreateQuotePdf: 'CreateQuotePdf',
    CreateQuoteDoc: 'CreateQuoteDoc',
    VoidQuotePdfs: 'VoidQuotePdfs',
    CreateQuote: 'CreateQuote',
    DuplicateQuote: 'DuplicateQuote',
    UpdateQuoteStatus: 'UpdateQuoteStatus',
    UpdateQuote: 'UpdateQuote',
    ReferQuote: 'ReferQuote',
    ExportQuoteToGSheetIntermediatePricing: 'ExportQuoteToGSheetIntermediatePricing',
    ExportQuoteToGSheet: 'ExportQuoteToGSheet',
    UpdateQuoteRiskTier: 'UpdateQuoteRiskTier',
    GenerateAiRiskAssessment: 'GenerateAiRiskAssessment',
    CopySalesforceCorrespondenceToPlatform: 'CopySalesforceCorrespondenceToPlatform',
    CreateAccountFromPolicyholder: 'CreateAccountFromPolicyholder',
    CreateOpportunityFromPolicy: 'CreateOpportunityFromPolicy',
    CreateScheduleRatings: 'CreateScheduleRatings',
    RefreshFormsSelection: 'RefreshFormsSelection',
    UpdateSelectedForm: 'UpdateSelectedForm',
    UpdateSelectedForms: 'UpdateSelectedForms',
    UpdateSubcontractedCost: 'UpdateSubcontractedCost',
    UpdateSubjectivities: 'UpdateSubjectivities',
    E2eTestSetup: 'E2eTestSetup',
    CreateUnderlyingPolicy: 'CreateUnderlyingPolicy',
    CreateUnderlyingPolicies: 'CreateUnderlyingPolicies',
    UpdateUnderlyingPolicy: 'UpdateUnderlyingPolicy',
    DeleteUnderlyingPolicy: 'DeleteUnderlyingPolicy',
    CreateVerificationRequest: 'CreateVerificationRequest',
    CreateGoogleSession: 'CreateGoogleSession',
    UpdateUser: 'UpdateUser',
    CreateSession: 'CreateSession',
    CreateGraySession: 'CreateGraySession',
    UpdateWorkersCompensationExposure: 'UpdateWorkersCompensationExposure',
  },
  Fragment: {
    ExposureNoteFragment: 'ExposureNoteFragment',
    LicenseChecksFragment: 'LicenseChecksFragment',
    PolicyChangeEndorsementFragment: 'PolicyChangeEndorsementFragment',
    PrimaryVehicleFragment: 'PrimaryVehicleFragment',
    PrimaryCoverageOptionFragment: 'PrimaryCoverageOptionFragment',
    UnderWritingAdjustmentsFragment: 'UnderWritingAdjustmentsFragment',
    ShepsyResponseOutputFragment: 'ShepsyResponseOutputFragment',
    UnmannedAircraftFragment: 'UnmannedAircraftFragment',
    ExcessGeneralLiabilityExposureFragment: 'ExcessGeneralLiabilityExposureFragment',
    ExpectedPayrollFragment: 'ExpectedPayrollFragment',
    SessionFragment: 'SessionFragment',
    UserFragment: 'UserFragment',
    OrganizationFragment: 'OrganizationFragment',
    TechUsage: 'TechUsage',
    OrganizationMemberFragment: 'OrganizationMemberFragment',
    LargeLossRunFragment: 'LargeLossRunFragment',
    VehicleAggregateObjectFragment: 'VehicleAggregateObjectFragment',
    ExcessAutoVehicleFragment: 'ExcessAutoVehicleFragment',
    AggregateLossRunFragment: 'AggregateLossRunFragment',
    ExcessAutoExposureFragment: 'ExcessAutoExposureFragment',
    ExcessAutoVehicleV2Fragment: 'ExcessAutoVehicleV2Fragment',
    ExcessAutoCoverageOptionsFragment: 'ExcessAutoCoverageOptionsFragment',
    CostOfHireFragment: 'CostOfHireFragment',
    PrimaryAutoLiabilityLossRunFragment: 'PrimaryAutoLiabilityLossRunFragment',
    PrimaryAutoLiabilityLargeLossRunFragment: 'PrimaryAutoLiabilityLargeLossRunFragment',
    PrimaryAutoPhysDamLossRunFragment: 'PrimaryAutoPhysDamLossRunFragment',
    PrimaryAutoPhysDamLargeLossRunFragment: 'PrimaryAutoPhysDamLargeLossRunFragment',
    PrimaryAutoLiabilityIndividualRiskRatingSurveyFragment:
      'PrimaryAutoLiabilityIndividualRiskRatingSurveyFragment',
    PrimaryAutoExposureFragment: 'PrimaryAutoExposureFragment',
    PrimaryGeneralLiabilityExpectedPayrollFragment:
      'PrimaryGeneralLiabilityExpectedPayrollFragment',
    PrimaryGeneralLiabilityExpectedSubcontractedCostFragment:
      'PrimaryGeneralLiabilityExpectedSubcontractedCostFragment',
    PrimaryGeneralLiabilityUnmannedAircraftFragment:
      'PrimaryGeneralLiabilityUnmannedAircraftFragment',
    PrimaryGeneralLiabilityLossRunFragment: 'PrimaryGeneralLiabilityLossRunFragment',
    PrimaryGeneralLiabilityLargeLossRunFragment: 'PrimaryGeneralLiabilityLargeLossRunFragment',
    PrimaryGeneralLiabilityIndividualRiskRatingSurveyFragment:
      'PrimaryGeneralLiabilityIndividualRiskRatingSurveyFragment',
    PrimaryGeneralLiabilityExposureFragment: 'PrimaryGeneralLiabilityExposureFragment',
    unmannedAircraftTakeOffWeightOptionsFragment: 'unmannedAircraftTakeOffWeightOptionsFragment',
    EmployeeBenefitLiabilityLimitOptionsFragment: 'EmployeeBenefitLiabilityLimitOptionsFragment',
    EmployeeBenefitLiabilityNumberOfEmployeesOptionsFragment:
      'EmployeeBenefitLiabilityNumberOfEmployeesOptionsFragment',
    UnmannedAircraftOwnershipAndOperationModifiersOptionsFragment:
      'UnmannedAircraftOwnershipAndOperationModifiersOptionsFragment',
    UnmannedAircraftUsageModifiersOptionsFragment: 'UnmannedAircraftUsageModifiersOptionsFragment',
    UnmannedAircraftPlaceOfOperationModifiersOptionsFragment:
      'UnmannedAircraftPlaceOfOperationModifiersOptionsFragment',
    MedicalExpenseLimitOptionsFragment: 'MedicalExpenseLimitOptionsFragment',
    PolicyLimitPolicyAggregateLimitILFPremOpsOptionsFragment:
      'PolicyLimitPolicyAggregateLimitILFPremOpsOptionsFragment',
    DeductibleGeneralLiabilityOptionsFragment: 'DeductibleGeneralLiabilityOptionsFragment',
    OverallAggregateCapTypeOptionsFragment: 'OverallAggregateCapTypeOptionsFragment',
    LabelValueFragment: 'LabelValueFragment',
    ExpectedPayrollRiskTierFragment: 'ExpectedPayrollRiskTierFragment',
    ExclusionsFragment: 'ExclusionsFragment',
    QuoteFragment: 'QuoteFragment',
    PricingLineItemFragment: 'PricingLineItemFragment',
    KeyValueFragment: 'KeyValueFragment',
    LossRateLabelValueFragment: 'LossRateLabelValueFragment',
    ColumnsMatrixSchemaFragment: 'ColumnsMatrixSchemaFragment',
    PricingLineItemFragmentMatrix: 'PricingLineItemFragmentMatrix',
    ProjectExcessGeneralLiabilityExpectedPayrollFragment:
      'ProjectExcessGeneralLiabilityExpectedPayrollFragment',
    ProjectExcessGeneralLiabilityExpectedSubcontractedCostFragment:
      'ProjectExcessGeneralLiabilityExpectedSubcontractedCostFragment',
    ProjectExcessGeneralLiabilityBuildoutSchedulesFragment:
      'ProjectExcessGeneralLiabilityBuildoutSchedulesFragment',
    ProjectExcessGeneralLiabilityExposureFragment: 'ProjectExcessGeneralLiabilityExposureFragment',
    FormPdfFragmentWithFormGroup: 'FormPdfFragmentWithFormGroup',
    FormPdfFragment: 'FormPdfFragment',
    FormPdfWithoutStateFragment: 'FormPdfWithoutStateFragment',
    FormGroupFragment: 'FormGroupFragment',
    FormGroupFragmentWithForms: 'FormGroupFragmentWithForms',
    FileFragment: 'FileFragment',
    QuotePdfFragment: 'QuotePdfFragment',
    ProjectPrimaryGeneralLiabilityExposureFragment:
      'ProjectPrimaryGeneralLiabilityExposureFragment',
    ProjectPrimaryAutoExposureFragment: 'ProjectPrimaryAutoExposureFragment',
    PolicyFragment: 'PolicyFragment',
    SelfAuditFragment: 'SelfAuditFragment',
    AuditQuestionResponseFragment: 'AuditQuestionResponseFragment',
    InsuranceProductFragment: 'InsuranceProductFragment',
    InvoiceOutput: 'InvoiceOutput',
    LargeLossRunRowFragment: 'LargeLossRunRowFragment',
    LargeLossRunPhysDamRowFragment: 'LargeLossRunPhysDamRowFragment',
    ClaimFragment: 'ClaimFragment',
    LossRunParsingAsyncRunFragment: 'LossRunParsingAsyncRunFragment',
    LossRunRowFragment: 'LossRunRowFragment',
    LossRunPhysDamRowFragment: 'LossRunPhysDamRowFragment',
    OrganizationIntegrationFragment: 'OrganizationIntegrationFragment',
    ProcoreCompaniesFragment: 'ProcoreCompaniesFragment',
    AutodeskHubsFragment: 'AutodeskHubsFragment',
    OrganizationLocationFragment: 'OrganizationLocationFragment',
    PolicyFileStructureItemFragment: 'PolicyFileStructureItemFragment',
    CaseStudiesGeneratedOutputFragment: 'CaseStudiesGeneratedOutputFragment',
    PolicyholderFragment: 'PolicyholderFragment',
    PrimaryNonAdmittedProjectPricingObjectFragment:
      'PrimaryNonAdmittedProjectPricingObjectFragment',
    PrimaryNonAdmittedPracticePricingObjectFragment:
      'PrimaryNonAdmittedPracticePricingObjectFragment',
    PricingFragment: 'PricingFragment',
    PricingMatrixFragment: 'PricingMatrixFragment',
    AutoIndividualRiskRatingSupportingInfoFragment:
      'AutoIndividualRiskRatingSupportingInfoFragment',
    GeneralLiabilityIndividualRiskRatingSupportingInfoFragment:
      'GeneralLiabilityIndividualRiskRatingSupportingInfoFragment',
    ProgramStructureFragment: 'ProgramStructureFragment',
    PrimaryLossAggregate: 'PrimaryLossAggregate',
    PrimaryProjectPricingObjectFragment: 'PrimaryProjectPricingObjectFragment',
    PrimaryPracticePricingFragment: 'PrimaryPracticePricingFragment',
    LossDevelopmentModelSelectedFragment: 'LossDevelopmentModelSelectedFragment',
    PrimaryOcpExposureFragment: 'PrimaryOcpExposureFragment',
    PrimaryRailroadExposureFragment: 'PrimaryRailroadExposureFragment',
    ProcoreUsageFragment: 'ProcoreUsageFragment',
    QuoteStatusChangedActivityDataFragment: 'QuoteStatusChangedActivityDataFragment',
    ExperienceRatingChangedActivityDataFragment: 'ExperienceRatingChangedActivityDataFragment',
    UnderwriterAdjustmentUpdatedDataObjectFragment:
      'UnderwriterAdjustmentUpdatedDataObjectFragment',
    ActionActivityObjectFragment: 'ActionActivityObjectFragment',
    CommentActivityObjectFragment: 'CommentActivityObjectFragment',
    QuoteActivitiesFragment: 'QuoteActivitiesFragment',
    QuoteActivityObjectFragment: 'QuoteActivityObjectFragment',
    QuoteSelectionFragment: 'QuoteSelectionFragment',
    QuoteWithEverything: 'QuoteWithEverything',
    QuoteDetailWithEverything: 'QuoteDetailWithEverything',
    ScheduleRatingsFragment: 'ScheduleRatingsFragment',
    ZodErrorFragment: 'ZodErrorFragment',
    BaseErrorFragment: 'BaseErrorFragment',
    SubcontractedCostFragment: 'SubcontractedCostFragment',
    UnderlyingPolicyFragment: 'UnderlyingPolicyFragment',
    WorkersCompensationExposureFragment: 'WorkersCompensationExposureFragment',
  },
};
