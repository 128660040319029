import { Controller, useForm } from 'react-hook-form';
import {
  useLossRunTpaConstantsQuery,
  useUploadAndParseLossRunFileToClaimsMutation,
  useLossRunCarriersConstantsQuery,
} from '../../graphql/operations/lossRunClaimParsing.generated';
import { namedOperations } from '../../graphql/namedOperations.generated';
import { showNotification } from '@mantine/notifications';
import { isEmpty, isNil } from 'lodash';
import React from 'react';
import { Autocomplete, Button, Checkbox, Group, List, Stack, Text, rem } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { IconFileText, IconUpload, IconX } from '@tabler/icons-react';
import { closeAllModals } from '@mantine/modals';
import onApolloError from '../../utils/utils';

const UploadLossRunFileModal = ({ quoteId }: { quoteId: string }) => {
  const { control, handleSubmit, setValue, watch, register } = useForm<{
    fileInput: File | null;
    carrier: string;
    tpa: string;
    isSummary: boolean;
    isNoLosses: boolean;
  }>({
    defaultValues: { fileInput: null, carrier: '', tpa: '', isSummary: false, isNoLosses: false },
  });

  const { data: tpaConstants } = useLossRunTpaConstantsQuery();
  const { data: carrierConstants } = useLossRunCarriersConstantsQuery();

  const [uploadAndParseLossRunFileToClaims, { loading }] =
    useUploadAndParseLossRunFileToClaimsMutation({
      refetchQueries: [
        namedOperations.Query.Claims,
        namedOperations.Query.LossRunFiles,
        namedOperations.Query.LossRunCarriersConstants,
        namedOperations.Query.LossRunTpaConstants,
      ],
      onCompleted: async (resp) => {
        if (resp.uploadAndParseLossRunFileToClaims.success === true) {
          showNotification({ message: 'Loss Run File Uploaded', color: 'green' });
          return;
        } else {
          showNotification({
            message: 'Unexpected error occurred, please contact the Eng team',
            color: 'red',
          });
          return;
        }
      },
      onError: onApolloError(),
    });

  const submit = handleSubmit(async (data) => {
    if (isNil(data.fileInput) || isEmpty(data.carrier)) {
      showNotification({ message: 'File was not uploaded or Carrier not selected', color: 'red' });
      return;
    }
    await uploadAndParseLossRunFileToClaims({
      variables: {
        input: {
          fileInput: data.fileInput,
          carrier: data.carrier,
          tpa: data.tpa,
          isSummary: data.isSummary,
          isNoLosses: data.isNoLosses,
          quoteId,
        },
      },
    });
    closeAllModals();
  });

  return (
    <Stack>
      <Controller
        name={'carrier'}
        rules={{ required: true }}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            label="Carrier"
            required
            error={fieldState.error?.type}
            data={carrierConstants?.lossRunCarriersConstants}
          />
        )}
      />
      <Controller
        name={'tpa'}
        control={control}
        render={({ field, fieldState }) => (
          <Autocomplete
            {...field}
            label="TPA (Optional)"
            error={fieldState.error?.type}
            data={tpaConstants?.lossRunTpaConstants}
          />
        )}
      />
      <Checkbox label="Summary only? (no claim level data)" {...register('isSummary')} />
      <Checkbox label="No Claims? (empty loss run)" {...register('isNoLosses')} />
      <Dropzone
        maxFiles={1}
        multiple={false}
        loading={loading}
        onDrop={(files) => setValue('fileInput', files[0])}
        onReject={() =>
          showNotification({ message: 'Loss Run File could not be uploaded', color: 'red' })
        }
        accept={[MIME_TYPES.pdf, MIME_TYPES.csv, MIME_TYPES.xls, MIME_TYPES.xlsx]}
      >
        <Dropzone.Accept>
          <IconUpload
            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
            stroke={1.5}
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
            stroke={1.5}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <Group>
            <IconFileText
              style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
              stroke={1.5}
            />
            <Stack gap="xs">
              <Text size="lg" inline>
                Drag or click to upload
              </Text>
              <Text size="sm" c="dimmed" inline>
                Attach a single file, no size limit restrictions.
              </Text>
            </Stack>
          </Group>
        </Dropzone.Idle>
      </Dropzone>
      {watch('fileInput') && (
        <List>
          <List.Item>
            <Text>{watch('fileInput')?.name}</Text>
          </List.Item>
        </List>
      )}

      <Button onClick={() => submit()} variant="filled" loading={loading}>
        Submit
      </Button>
    </Stack>
  );
};

export default UploadLossRunFileModal;
