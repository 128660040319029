import numeral from 'numeral';

export const labelValueMap = (input: Array<{ label: string; value: string }>) =>
  input.reduce<Record<string, string>>((agg, curr) => ({ ...agg, [curr.label]: curr.value }), {});

export const defaultNumberPaste = (value: string) => numeral(value).value()?.toString() ?? '';

export const trimThenLookup = ({
  input,
  records,
}: {
  input: string;
  records?: Record<string, string>;
}) => {
  const trimmedInput = input.trim();
  if (!records) {
    return trimmedInput;
  }

  return records[trimmedInput] ?? trimmedInput;
};

export const isEmptyCell = (cell: unknown) => {
  if (typeof cell === 'string') {
    return cell.trim() === '';
  }

  return cell === null || cell === undefined;
};
